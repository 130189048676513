import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../../components/Layout';
import { parseYoutubeLink } from '../../utils/common';

import iconTick from '../../assets/images/svg/tick.svg';
import IconInst from '../../assets/images/svg/icons8-instagram.inline.svg';
import IconClose from '../../assets/images/svg/icon-close-modal.inline.svg';
import IconClock from '../../assets/images/svg/icons8-clock.inline.svg';
import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';
import imageLogoIg from '../../assets/images/graduates/tefl-ig-logo.jpg';
import IconClosePopup from '../../assets/images/close-2.svg';

import '../../assets/styles/pages/tefl-iberia-graduates-page.scss';

const Graduates = ({ data }) => {
  const [showPopup, setShowPopup] = useState(true);
  const allPrismicGraduates = data.allPrismicGraduates.edges;
  const firstGraduate = allPrismicGraduates.filter(
    ({
      node: {
        data: { pinned },
      },
    }) => pinned === true
  );
  const otherGraduates = allPrismicGraduates.filter(
    ({
      node: {
        data: { pinned },
      },
    }) => pinned !== true
  );

  const allPrismicInstagramPosts = data.allPrismicInstagramPosts.edges;

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === 'Escape') handleClosePopup();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <Helmet>
        {/* <!-- Meta Pixel Code --> */}
        <script>
          {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1389098578493723');
            fbq('track', 'PageView');`}
        </script>
        <noscript>{`<img height=“1” width=“1" style=“display:none”
        src=“https://www.facebook.com/tr?id=1389098578493723&ev=PageView&noscript=1”
        />`}</noscript>

        {/* <!-- End Meta Pixel Code --> (edited)  */}
      </Helmet>

      <Layout
      // title="Course Reviews & Testimonials from Graduates"
      // description="Read reviews and testimonials from previous Tefl Iberia students and graduates, and apply to become a student online."
      >
        <div className="tefl-iberia-graduates-page">
          <section className="first-section">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="block_l c-benefit-list ">
                    <h1 className="c-title-42 first-section__title">TEFL Iberia Graduates</h1>
                    <p className="c-text-18 first-section__description">
                      We love creating memorable experiences here at TEFL Iberia. We pride ourselves
                      on providing high-quality training, an essential support system and most
                      importantly a life changing opportunity to work abroad. Hear it from our
                      graduates first hand:
                    </p>
                    <h4>In the videos the graduates highlight:</h4>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Feeling confident to stand in front of a class and teach
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Teaching real students</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Finding a job quickly after the course</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  {firstGraduate.map(({ node }) => (
                    <div className="block_r" key={node.id}>
                      <div className="block_r__video">
                        <iframe
                          width="560"
                          height="315"
                          src={parseYoutubeLink(node?.data?.video?.embed_url)}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                      {/* <h3 className="block_r__title">{node?.data?.title?.text}</h3> */}
                      {/* <p className="block_r__description">{node?.data?.description?.text}</p> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section className="second-section">
            <div className="container">
              <div className="row">
                {otherGraduates.map(({ node }) => (
                  <div className="col-lg-4 col-sm-6 col-12" key={node.id}>
                    <div className="review-item">
                      <div className="review-item__video">
                        <iframe
                          width="560"
                          height="315"
                          src={parseYoutubeLink(node?.data?.video?.embed_url)}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                      <h3 className="c-text-16 review-item__title">{node?.data?.title?.text}</h3>
                      <p className="c-text-14 review-item__description">
                        {node?.data?.description?.text}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <SectionInstagramPosts allPrismicInstagramPosts={allPrismicInstagramPosts} />

          {showPopup && (
            <div className="popup-backdrop">
              <div className="popup">
                <button onClick={handleClosePopup} className="popup__close" type="button">
                  <img src={IconClosePopup} alt="close popup" />
                </button>
                <h2 className="popup__title">Subscribe to our newsletter</h2>
                <div className="popup__wrapper">
                  <p className="popup__content">
                    Subscribe to our mailing list to receive more information on the courses,
                    locations and experience of taking TEFL courses in Spain.
                  </p>{' '}
                  <Link className="c-btn c-btn--red" to="/subscribe">
                    Subscribe
                  </Link>
                </div>
                {/* <p className="popup__hint">Enter to win our free course giveaway</p> */}
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

const SectionInstagramPosts = ({ allPrismicInstagramPosts }) => {
  const LIMIT_IG_POSTS = 6;

  const [selectedPostId, setSelectedPostId] = useState(null);
  const [isShowingMore, setIsShowingMore] = useState(false);

  const showPostDetails = selectedPostId !== null;
  const postDetails = allPrismicInstagramPosts.find(({ node }) => node.id === selectedPostId)?.node
    ?.data;

  // console.log(allPrismicInstagramPosts, postDetails);
  return (
    <section className="third-section c-section-py">
      <div className="container">
        <div className="row">
          <div className="third-section__titles">
            <h2 className="c-title-34">
              Follow us on social media and keep up to date with student Life
            </h2>
            <p className="c-text-18">Keep up to date with our students on Instagram</p>
          </div>

          {allPrismicInstagramPosts
            .slice(0, isShowingMore ? allPrismicInstagramPosts.length : LIMIT_IG_POSTS)
            .map(
              ({
                node: {
                  id,
                  data: { title, description, tags, date, image, instagram_link },
                },
              }) => {
                return (
                  <div key={id} className="col-lg-4 col-md-6 col-12 mb-4">
                    <div className="instagram-card">
                      <div className="card__head">
                        <a
                          href={'https://www.instagram.com/tefliberia/'}
                          target="_blank"
                          className="head__wrapper"
                        >
                          <img
                            className="card__authorImg"
                            src={imageLogoIg}
                            width={36}
                            height={36}
                            alt="TEFL Iberia @tefliberia"
                          />
                          <div className="card__authorData">
                            <h3 className="card__authorName">TEFL Iberia @tefliberia</h3>
                            <span className="card__authorDate">{date}</span>
                          </div>
                          <IconInst className="card__iconInstagram" width={35} height={35} />
                        </a>
                      </div>
                      <div onClick={() => setSelectedPostId(id)} className="card__content">
                        {image?.url && (
                          <img
                            src={image.url}
                            alt={title?.text || 'tefl instagram'}
                            className="card__thumb"
                            width={400}
                          />
                        )}
                        <div className="card__body">
                          {title?.text && <p className="card__description">{title.text}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          <div className="third-section__load-more-btn ">
            {allPrismicInstagramPosts?.length > LIMIT_IG_POSTS && (
              <button
                type="button"
                className="c-btn c-btn--red-border mr-4"
                onClick={() => setIsShowingMore(!isShowingMore)}
              >
                {!isShowingMore ? 'Show More' : 'Show Less'}
              </button>
            )}
            <a
              className="c-btn c-btn--red"
              target="_blank"
              href="https://www.instagram.com/tefliberia/"
            >
              Follow Us
            </a>
          </div>

          <div className={`backdrop ${showPostDetails ? 'visible' : ''}`}>
            <div className="modal">
              <div className="post">
                <a
                  className="post__thumb"
                  href={postDetails?.instagram_link?.url ?? 'https://www.instagram.com/tefliberia/'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={postDetails?.image?.url}
                    alt={postDetails?.title?.text ?? 'TEFL Iberia'}
                  />
                </a>
                <div className="post__body">
                  <div className="post__head">
                    <a
                      href={'https://www.instagram.com/tefliberia/'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="post__title">TEFL Iberia @tefliberia</span>
                    </a>
                    <a
                      href={
                        postDetails?.instagram_link?.url ?? 'https://www.instagram.com/tefliberia/'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconInst className="post__iconInstagram" width={35} height={35} />
                    </a>
                  </div>
                  {postDetails?.title?.text && (
                    <a
                      href={
                        postDetails?.instagram_link?.url ?? 'https://www.instagram.com/tefliberia/'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="post__title mb-2">{postDetails.title.text}</p>
                    </a>
                  )}
                  {postDetails?.description?.text && (
                    <p className="post__description mb-3">{postDetails.description.text}</p>
                  )}
                  {postDetails?.tags && (
                    <p className="post__tags">
                      {postDetails?.tags.split(' ').map((tag) => (
                        <a
                          className="post__tag"
                          href={`https://www.instagram.com/explore/tags/${tag.slice(1)}/`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {tag}
                        </a>
                      ))}
                    </p>
                  )}
                  {postDetails?.date && (
                    <p className="post__date">
                      <IconClock /> {postDetails.date}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <button onClick={() => setSelectedPostId(null)} className="modal__close">
              <IconClose />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  query {
    allPrismicGraduates(sort: { order: DESC, fields: last_publication_date }) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            description {
              text
            }
            video {
              embed_url
            }
            pinned
          }
        }
      }
    }
    allPrismicInstagramPosts(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            date(formatString: "DD MMMM")
            image {
              url
            }
            description {
              text
            }
            tags
            instagram_link {
              url
            }
          }
        }
      }
    }
  }
`;

export default Graduates;
